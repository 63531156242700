import React, { useState } from "react";
import PropTypes from 'prop-types';
import "./application-card.scss";
import { getScholarshipStatusMessage } from "../ApplicationCommon/ApplicationCommon";
import Person from "../../SVG/Person";
import Close from "../../SVG/Close";
import { MAIN_CONFIG } from "../../../../config/main";
import ImagePlaceholder from "../../../Components/SVG/ImagePlaceholder";
import { useRouter } from "next/router";
import {formatDeadlineDate} from "../../../Utility/HelperFunctions";
import DrawnArrow from "../../SVG/DrawnArrow";
import { getDynamicClassName } from "../../../Utility/ApplicationFormUtility";
import FormatNumber, { floatFormatter } from "../../../Utility/FormatNumber";
import CTAImageTag from "../../CTAImageTag/CTAImageTag";

export const ApplicationCard = ({
  tbf_award_amount = null,
  tbf_award_amount_notes = '',
  setLoading,
  color,
  logo,
  title,
  status,
  scholarshipStatus,
  resumeURL,
  applicantRecommendations = [],
  scholarshipHeaderLogo,
  isDonationForm,
  hideApplicationStatusForApplicant = false,
  nomineeFirstName,
  nomineeLastName,
  ShowNomineeNameInCard = false,
  daysRemaining,
  scholarshipDeadlineDate,
  programUrl,
  exitReason = '',
  showExitReason = false,
  elementsProgramUrl = '',
  elementsApplicationUrl = '',
  isElementsProgram = false,
  isElementsApp = false,
  hideAwardAmountNotes = false,
  ApplicationCode = ""
}) => {
  let parsedText = exitReason?.replace(/<[^>]+>/g, '');
  const formattedTbfAwardAmount = tbf_award_amount ? FormatNumber(floatFormatter(tbf_award_amount)) : ""
  const showTbfAwardAmount = (["Winner", "Selected"].includes(status) && tbf_award_amount && !hideAwardAmountNotes)
  const showTbfAwardAmountNotes = (!hideAwardAmountNotes && tbf_award_amount_notes)
  const formattedDeadlineDate = scholarshipDeadlineDate
    ? new Date(scholarshipDeadlineDate) > new Date()
      ? formatDeadlineDate(new Date(scholarshipDeadlineDate))
      : ""
    : "";
  const [activeTab, setActiveTab] = useState(false);
  const router = useRouter();

  const handleLeave = (e) => {
    e.preventDefault();
    setActiveTab(false);
  };

  const handleFocus = (e) => {
    e.preventDefault();
    setActiveTab(true);
  };

  let handleViewProgram = (e) => {
    e.preventDefault();
    if (isElementsProgram) {
      window.location.href = elementsProgramUrl
    } else {
      router.push(programUrl, undefined, { shallow: true });
    }
  };

  const renderResumeApplicatuonText = () => {
    if (isElementsApp && status === "Pending Contributor Submissions") {
      return "View Application & Check Contributor Status";
    }

    if (
      (["Incomplete", "Draft", "Prototype"].includes(status) &&
        scholarshipDeadlineDate &&
        new Date(scholarshipDeadlineDate) < new Date()) ||
      !["Accepting Applications", "Prototype"].includes(scholarshipStatus)
    ) {
      return "View Application";
    } else if (["Incomplete", "Draft", "Prototype"].includes(status)) {
      return "Complete Application";
    }
    return "View Application";
  };

  const handleRedirect = (e) => {
    e.preventDefault();
    if (isElementsApp) {
      window.location.href = elementsApplicationUrl;
    } else if (MAIN_CONFIG.RESUME_STATUS.includes(status)) {
      setLoading(true);
      router.push(resumeURL, undefined, { shallow: true });
    }
  };

  let progressRecommender = 0;
  if (applicantRecommendations?.length > 0) {
    progressRecommender =
      (applicantRecommendations.filter(
        (recommendation) =>
          recommendation.recommendation_status__c === "Submitted"
      )?.length /
        applicantRecommendations?.length) *
      100;
  }
  const handleKeyDown = (event, callBack) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      callBack(); // Call the onClick function when Enter is pressed
    }
  };
  const getConvertedColor = (primaryColor, updatedColor, color, defaultColor) => {
    if (primaryColor == '#ffffff') {
      return updatedColor;
    } else if (color) {
      return color;
    } else {
      return defaultColor
    }
  }

  const primaryColorConvert = color?.toLowerCase();
  const borderStyle = `15px solid ${getConvertedColor(primaryColorConvert, "#ebebeb", color, "#008067")}`;

  const borderTopStyle = {
    borderTop: borderStyle
  };

  const showExitReasonMessage = () => {
    if (status === "Ineligible" && showExitReason && exitReason) {
      return true;
    } else {
      return false;
    }
  };

  const renderRecommenderList = () => {
    return (
      <div className={getDynamicClassName(activeTab, "application-card__recommendation-popup  recommendation-popup__show", "application-card__recommendation-popup  recommendation-popup__hide")}>
        <div className="application-card-recommendation-popup-main-cointainer">
        <button onClick={(e) => handleLeave(e)} onKeyDown={(e) => handleKeyDown(e, () => handleLeave(e))} className="application-card__recommendation-popup-closed-btn top-btn"><Close color={MAIN_CONFIG.COLORS.fillRed} /></button>
        {applicantRecommendations?.length > 0 &&
          <>

            <div className="recommendation-list__complete" style={{ paddingLeft: '10px' }}>
              {applicantRecommendations.filter(recommendation => recommendation.recommendation_status__c === 'Submitted')?.length > 0 &&
                <div className="H2DesktopGreen">Done</div>
              }
              {applicantRecommendations.filter(recommendation => recommendation.recommendation_status__c === 'Submitted').map((recommendation, i) =>
                <div className="recommendation-list__complete-item" key={recommendation?.id}>
                  <div className="recommendation-list__complete-item-icon">
                    <Person color={MAIN_CONFIG.COLORS.fillGreen} secondaryColor={MAIN_CONFIG.COLORS.fillWhite} />
                  </div>
                  <div className="recommendation-list__complete-item-content">
                    <div className="recommendation-list__complete-name CTABlack">{recommendation.recommender_full_name}</div>
                    <div className="recommendation-list__complete-email CTAGrey">{recommendation.recommender_email}</div>
                  </div>
                </div>
              )}
            </div>

            <div className="recommendation-list__un-complete" style={{ paddingLeft: '10px' }}>
              {applicantRecommendations.filter(recommendation => recommendation.recommendation_status__c !== 'Submitted')?.length > 0 &&
                <div className="H2DesktopGreen">pending</div>
              }
              {applicantRecommendations.filter(recommendation => recommendation.recommendation_status__c !== 'Submitted').map((recommendation, i) =>
                <div className="recommendation-list__complete-item" key={recommendation?.id}>
                  <div className="recommendation-list__complete-item-icon">
                    <Person className='' color={MAIN_CONFIG.COLORS.fillRed} secondaryColor={MAIN_CONFIG.COLORS.fillWhite} />
                  </div>
                  <div className="recommendation-list__complete-item-content">
                    <div className="recommendation-list__complete-name CTABlack">{recommendation.recommender_full_name}</div>
                    <div className="recommendation-list__complete-email CTAGrey">{recommendation.recommender_email}</div>
                  </div>
                </div>
              )}
            </div>
          </>
        }

        </div>
      </div>
    )
  }

  const renderApplicationCard = () => {
    return (
      <div className="application-card-main-container" style={{ borderTopStyle }}tabIndex={0}>
        <div className="application-card-main-container__days-left">
          {/* {daysRemaining > 0 && formattedDeadlineDate && ( */}
              <span className="application-card__daysremaining">
                {/* {daysRemaining} DAYS LEFT */}
                {ApplicationCode}
              </span>
            {/* )} */}
        </div>

        <div className="application-card-main-container__logo-and-days-left">
          <div className="application-card-main-container__logo-and-days-left__logo">
            {logo || scholarshipHeaderLogo ? (
              <CTAImageTag
                className="application-card__card-front__logo"
                data={logo ? logo : scholarshipHeaderLogo}
                title={`${title} logo`}
              />
            ) : (
              <ImagePlaceholder
                className="application-card__card-front__logo"
                ariaLabel="Scholarship Logo"
                ariaLabelDescription=""
                height="100px"
                width="100%"
              />
            )}
          </div>
        </div>


        <div className="application-card-main-container__new-container">
          <div className="application-card-main-container__scholarship-name">
            {title}
          </div>

          {ShowNomineeNameInCard && (
            <div className="application-card-main-container__nominee-name">
              {nomineeFirstName ? <span>{nomineeFirstName}</span> : ""}{" "}
              {nomineeLastName ? <span>{nomineeLastName}</span> : ""}
            </div>
          )}

            {!["Winner", "Selected"].includes(status) &&
              !tbf_award_amount && (
                <div className="application-card-main-container__scholarship-deadline-date">
                  {formattedDeadlineDate && !ShowNomineeNameInCard && (
                    <span>Submit By: {formattedDeadlineDate}</span>
                  )}
                </div>
              )}

          <div className="application-card-main-container__program-status-tbf-award-amount">
            <div className="application-card-main-container__program-status">
              <div className="application-card-main-container__program-status__title">
                Program Status
              </div>
              <span className="application-card-main-container__program-status__badge">
                {scholarshipStatus === "Accepting Applications" &&
                  isDonationForm
                  ? "Accepting Donations"
                  : scholarshipStatus}
              </span>
            </div>
            {(tbf_award_amount || tbf_award_amount_notes) && 
              <div className="application-card-main-container__tbf-award-amount-container">
                <div className={`application-card-main-container__tbf-award-amount-container__tbf-award-amount-${(showTbfAwardAmountNotes || showTbfAwardAmount) ? "show" : "hide"}`} title={`$${formattedTbfAwardAmount} ${tbf_award_amount_notes || ''}`}>
                  <div className="award-amoun">
                   {showTbfAwardAmount && `$${formattedTbfAwardAmount}`}
                  </div>
                 {showTbfAwardAmountNotes && 
                  <div className="tbf-award-amount-notes">
                    {tbf_award_amount_notes}
                  </div>}
                </div>
              </div>}
          </div>

          <div className="application-card-main-container__application-status-and-contributor-status">
            <div className="application-card-main-container__application-status-and-contributor-status__application-status">
              {/* <div className="application-card-main-container__application-status-and-contributor-status"> */}
              {!hideApplicationStatusForApplicant && (
                <>
                  <div className="application-card-main-container__application-status-and-contributor-status__title">
                    Application Status
                  </div>
                  <span className="application-card-main-container__application-status-and-contributor-status__app-status" title={showExitReasonMessage() && parsedText}>
                    {getScholarshipStatusMessage(status, isDonationForm)} {showExitReasonMessage() && `- ${parsedText}`}
                  </span>
                </>
              )}
              {/* </div> */}
            </div>

            {applicantRecommendations?.length > 0 && (
              <>
                <div className="application-card-main-container__application-status-and-contributor-status__contributor-status">
                  <div className="application-card-main-container__application-status-and-contributor-status__title">
                    Contributor Status
                  </div>
                  <div
                    className="application-card-main-container__application-status-and-contributor-status__badge" style={{ cursor: 'pointer' }}
                    onClick={(e) => handleFocus(e)}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => handleKeyDown(e, () => handleFocus(e))}
                    ariaLabel={title}
                  >
                    {/* 1/3 Completed */}
                    {applicantRecommendations?.length > 0
                      ? applicantRecommendations.filter(
                        (recommendation) =>
                          recommendation.recommendation_status__c ===
                          "Submitted"
                      )?.length
                      : 0}
                    {""}/{""}
                    {applicantRecommendations?.length > 0
                      ? applicantRecommendations?.length
                      : 0}{" "}
                    Completed
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="application-card-main-container__view-award-program-and-complete-application">
          <button
            className="application-card-main-container__view-award-program-and-complete-application__view-award-program"
            style={{ display: "flex", alignItems: "center" }}
            onClick={handleViewProgram}
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e, () => handleViewProgram(e))}
            aria-label="View Program Details"
          > View Program Details
            <DrawnArrow
              className="account-switcher__arrow application-card-main-container__view-award-program-and-complete-application__view-award-program-arrow "
              color={MAIN_CONFIG.COLORS.fillBlue}
              ariaLabel=""
            />
          </button>

          <button 
            className="application-card-main-container__view-award-program-and-complete-application__complete-application"
            style={{ display: "flex", alignItems: "center" }}
            onClick={handleRedirect}
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e, () => handleRedirect(e))}
            aria-label={`${renderResumeApplicatuonText()}`}>
              {renderResumeApplicatuonText()}
            <DrawnArrow
              className="account-switcher__arrow"
              color={MAIN_CONFIG.COLORS.fillBlue}
              ariaLabel=""
              width="20"
              height="20"
            />
          </button>
        </div>
      </div>
    )
  }


  return (
        <div className="application-card">
          {renderApplicationCard()}
          {renderRecommenderList()}
        </div>
  );
};

// handle propTypes

ApplicationCard.propTypes = {
  showExitReason: PropTypes.bool,
  elementsProgramUrl: PropTypes.string,
  elementsApplicationUrl: PropTypes.string,
  isElementsProgram: PropTypes.bool,
  isElementsApp: PropTypes.bool,
  color: PropTypes.string,
  tbf_award_amount_notes: PropTypes.string,
  tbf_award_amount: PropTypes.any,
  hideAwardAmountNotes: PropTypes.bool
};